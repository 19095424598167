import {
  Box,
  Flex,
  Button,
  Container,
  Stack,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import FadeInAnimation from '../components/UX/FadeInAnimation';
import MagicText from '../components/UX/MagicText';
import ImgTextModule from '../components/elements/ImgTextModule';
import RouterLink from '../components/Utility/Routing/RouterLink';
import Headerelement from '../components/elements/Headerelement';
import TeamTri from '../components/TeamTri';
import KontaktFooter from '../components/KontaktFooter';
const IndexPage = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode('light');
  });

  return (
    <>
      <Helmet>
        <title>KDW | Personalmanagement und Managementberatung</title>
        <meta
          name='description'
          content='kdw HR bietet Kunden Dienstleistungen von der Personalvermittlung, über qualifizierte Arbeitnehmerüberlassung bis hin zu Management- und Fördermittelberatung im gesamten Personalmanagementbereich. Arbeitnehmer finden bei uns langfristige Karriereoptionen genauso wie neue Herausforderungen vornehmlich im Industrie-, Gewerbe- und Officesegment.'
        />
        <link rel='canonical' href='https://kdw-hr.de' />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Helmet>

      {/* CONTAINER MAGICTEXT */}
      <Container minW='100%' mt={0.5} p={0}>
        <Headerelement />
        <MagicText
          text={
            'Der Weg ist immer das Ziel. Wir helfen Ihnen, Ihre persönlichen Ziele langfristig und erfolgreich zu erreichen.'
          }
          quoteOrigin=''
          fontSize={{ md: '6xl', base: '3xl' }}
          coloranimtext={'#FABE64'}
          scrollLength={'250vh'}
          gap={{ md: 0, base: 0 }} //gap between words
          videoSrc='34292_men_working_on_computers_in_office_By_Yigal_Ohanna_Artlist_HD.mp4'
          scrollStartFactor={-0.1}
          scrollEndFactor={0.5}
          textColor={'brand.green'}
          showProgressCircle={true}
          progressColor={'brand.green'}
          showProgressLine={true}
          progressLineColor={'brand.violet'}
        />
      </Container>
      <ImgTextModule
        src={
          '439342_Chess_Chess_Pieces_Game_King_By_Storyfootage_Artlist_HD.mp4'
        }
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h='95vh'
        mediaOpacity={0.8}
        bgColor='brand.gray.100'
      >
        <Container variant='layoutContainer' mt={8}>
          <Box p={{ base: 0, lg: '8' }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.4}
              initialX={0}
              initialY={-10}
            >
              {' '}
              <Text
                mx={'auto'}
                textAlign={'center'}
                color='white'
                fontWeight='normal'
                fontSize={{ base: '2xl', lg: '3xl' }}
              >
                Arbeitgeber
              </Text>
              <Text
                mx={'auto'}
                textAlign={'center'}
                color='brand.green'
                fontWeight='normal'
                fontSize={{ base: '3xl', lg: '6xl' }}
              >
                Unternehmen.
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color='brand.gray.1000'
                mb={24}
                maxW={{ base: '100%', lg: '50%' }}
                fontSize='xl'
                lineHeight={'short'}
                mx={'auto'}
                textAlign={'center'}
              >
                kdw HR bietet Kunden Dienstleistungen von der
                Personalvermittlung, über qualifizierte Arbeitnehmerüberlassung
                bis hin zu Management- und Fördermittelberatung im gesamten
                Personalmanagementbereich.
              </Text>
            </FadeInAnimation>
          </Box>
        </Container>
      </ImgTextModule>

      <ImgTextModule
        src={'All-4.mp4'}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h='85vh'
        mediaOpacity={0.7}
        bgColor='black'
      >
        <Container variant='layoutContainer' mt={8}>
          <Box p={{ base: 0, lg: '8' }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color='white'
                fontWeight='normal'
                mx={'auto'}
                textAlign={'center'}
                fontSize={{ base: '2xl', lg: '2xl' }}
              >
                Arbeitnehmer
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.4}
              initialX={0}
              initialY={-10}
            >
              <Text
                color='brand.green'
                mx={'auto'}
                textAlign={'center'}
                fontWeight='normal'
                fontSize={{ base: '3xl', lg: '6xl' }}
              >
                Karriere und
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.4}
              initialX={0}
              initialY={-10}
            >
              <Text
                color='brand.yellow'
                fontWeight='normal'
                mx={'auto'}
                textAlign={'center'}
                fontSize={{ base: '3xl', lg: '6xl' }}
              >
                persönliche Entwicklung.
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color='brand.gray.1000'
                mb={8}
                maxW={{ base: '100%', lg: '50%' }}
                fontSize='xl'
                lineHeight={'short'}
                mx={'auto'}
                textAlign={'center'}
              >
                Arbeitnehmer finden bei uns langfristige Karriereoptionen
                genauso wie neue Herausforderungen vornehmlich im Industrie-,
                Gewerbe- und Officesegment.
              </Text>
            </FadeInAnimation>
            <FadeInAnimation threshold={0.4} duration={1} delay={0.7}>
              <Flex mx={'auto'} justifyContent={'center'}>
                <RouterLink
                  mx='auto'
                  display={'inline-block'}
                  isExternal={'false'}
                  link={'/stellen'}
                >
                  <Button
                    mt={2}
                    px={14}
                    pt={4}
                    pb={9}
                    mx='auto'
                    fontSize='md'
                    textAlign='center'
                    variant={'solid'}
                    color='white'
                    bg='brand.violet'
                  >
                    Freie Stellen
                  </Button>
                </RouterLink>
              </Flex>
            </FadeInAnimation>
          </Box>
        </Container>
      </ImgTextModule>
      <KontaktFooter />
    </>
  );
};

export default IndexPage;
